import { createAsyncThunk } from "@reduxjs/toolkit";
import { SOMETHING_WENT_WRONG_MESSAGE } from "constant";
import API from "../../utils/axios";

export const fetchThreadsAction = createAsyncThunk<any>("fetch threads", async (arg, thinkAPI) => {
	try {
		console.log("fetching threads");
		const { data, status } = await API.get("clients");
		console.log(`done fetching ${data.data.length} threads`);
		if (status !== 200) {
			throw Error(data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
		}
		return thinkAPI.fulfillWithValue(data.data);
	} catch (error: any) {
		console.log("error");
		return thinkAPI.rejectWithValue(error.data.message);
	}
});

export const seenMessageAction = createAsyncThunk<any, { id: string }>("seen message", async ({ id }, thinkAPI) => {
	try {
		const { data, status } = await API.put(`message-views?chatId=${id}`);
		if (status !== 200) {
			throw Error(data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
		}
		return thinkAPI.fulfillWithValue(id);
	} catch (error: any) {
		return thinkAPI.rejectWithValue(error.data.message);
	}
});

export const fetchSingleThread = createAsyncThunk<any, { thread: any }>(
	"get single thread",
	async ({ thread }, thinkAPI) => {
		try {
			const { data, status } = await API.get(`messages/?id=${thread.chat.id}&page=1&pageSize=100`);
			if (status !== 200) {
				throw Error(data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			}
			return thinkAPI.fulfillWithValue(data.data);
		} catch (error: any) {
			return thinkAPI.rejectWithValue(error.data.message);
		}
	},
);

export const addThread = createAsyncThunk<any, { thread: any }>("add thread", async (thread, thinkAPI) => {
	try {
		return thinkAPI.fulfillWithValue(thread);
	} catch (error: any) {
		return thinkAPI.rejectWithValue(error.data.message);
	}
});

export const blockThread = createAsyncThunk<any, { id: any }>("block thread", async (id, thinkAPI) => {
	try {
		return thinkAPI.fulfillWithValue(id);
	} catch (error: any) {
		return thinkAPI.rejectWithValue(error.data.message);
	}
});

export const getUserDetailsWidthMessage = createAsyncThunk<any, { id: any }>(
	"get user details with message",
	async (id, thinkAPI) => {
		try {
			return thinkAPI.fulfillWithValue(id);
		} catch (error: any) {
			return thinkAPI.rejectWithValue(error.data.message);
		}
	},
);

export const sendMessage = createAsyncThunk<any, { id: string; message: object }>(
	"send message",
	async ({ id, message }, thinkAPI) => {
		try {
			return thinkAPI.fulfillWithValue({ id, message });
		} catch (error: any) {
			return thinkAPI.rejectWithValue(error.data.message);
		}
	},
);

export const sendImage = createAsyncThunk("sendImage", async (payload, thinkAPI) => {
	try {
		const { data, status } = await API.postForm(`/user/send-image`, payload);
		if (status !== 200) {
			throw Error(data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
		}
		return thinkAPI.fulfillWithValue(data.data);
	} catch (error: any) {
		return thinkAPI.rejectWithValue(error.data.message);
	}
});

export const resolveChat = createAsyncThunk(
	"resolveChat",
	async ({ reason, chatId, type, status: chatStatus }: any, thinkAPI) => {
		try {
			const { data, status } = await API.post("/chatResolutions", { slug: reason, type, chatId, status: chatStatus });
			console.log({ data });
			if (status !== 201) {
				return thinkAPI.rejectWithValue(data.message ?? SOMETHING_WENT_WRONG_MESSAGE);
			} else {
				return thinkAPI.fulfillWithValue(data.data);
			}
		} catch (error: any) {
			return thinkAPI.rejectWithValue(error.data.message);
		}
	},
);

export const chatResolution = createAsyncThunk(
	"messages/chatResolution",
	async ({ slug, chatId, type, status }: { slug: string; chatId: string; type: string; status: string; }, { rejectWithValue }) => {
		try {
			const response = await API.post("/chatResolutions", { slug, chatId, type, status });
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	}
);
