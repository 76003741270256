import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { Socket, io } from "socket.io-client";
import { AppDispatch } from '../store';
import { fetchThreadsAction } from '../store/actions/messageAction';

interface SocketContextType {
	socket: Socket | null;
	isConnected: boolean;
}

const SocketContext = createContext<SocketContextType>({ socket: null, isConnected: false });
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL as string;

export const useSocket = () => {
	return useContext(SocketContext);
};

export const SocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [socket, setSocket] = useState<Socket | null>(null);
	const [isConnected, setIsConnected] = useState(false);
	const [tokens, setTokens] = useState<string | null>(localStorage.getItem("tokens"));
	const ref = useRef<number>(0);
	const dispatch = useDispatch<AppDispatch>();

	// Add an effect to watch for localStorage changes
	useEffect(() => {
		const handleStorageChange = (event: StorageEvent | null) => {
			console.log("Storage changed, updating tokens");
			setTokens(localStorage.getItem("tokens"));
		};

		// Listen for cross-tab changes
		window.addEventListener('storage', handleStorageChange);
		
		// Create a custom event for same-tab changes
		window.addEventListener('localStorageUpdated', () => handleStorageChange(null));

		return () => {
			window.removeEventListener('storage', handleStorageChange);
			window.removeEventListener('localStorageUpdated', () => handleStorageChange(null));
		};
	}, []);

	useEffect(() => {
		console.log("SocketProvider useEffect triggered");
		console.log("Current tokens:", tokens ? "exists" : "none");

		if (!tokens) {
			console.log("No tokens available, skipping socket connection");
			return;
		}

		if (!socket) {
			console.log("Attempting to create new socket connection");
			try {
				const accessToken = JSON.parse(tokens)?.access_token;
				if (!accessToken) {
					console.log("No access token found in tokens object");
					return;
				}
				console.log("Access token found, initializing socket");

				const newSocket = io(SOCKET_SERVER_URL, {
					extraHeaders: {
						Authorization: `Bearer ${accessToken}`,
					},
					reconnection: true,
					reconnectionAttempts: 30,
					reconnectionDelay: 1000,
				});

				newSocket.on('connect_attempt', () => {
					console.log("Socket attempting connection...");
				});

				newSocket.on('connect', () => {
					console.log("Socket successfully connected!");
					setIsConnected(true);
					dispatch(fetchThreadsAction());
				});

				newSocket.on('disconnect', (reason) => {
					console.log("Socket disconnected. Reason:", reason);
					setIsConnected(false);
				});

				newSocket.on('connect_error', (error) => {
					console.error("Socket connection error:", error.message);
				});

				newSocket.on('reconnect_attempt', (attemptNumber) => {
					console.log(`Socket attempting reconnection... Attempt #${attemptNumber}`);
				});

				newSocket.on('reconnect_failed', () => {
					console.log("Socket reconnection failed after all attempts");
				});

				ref.current = 1;
				setSocket(newSocket);
				console.log("New socket instance created");
			} catch (error) {
				console.error("Error setting up socket connection:", error);
			}
		}

		return () => {
			console.log("Cleanup function called, disconnecting socket");
			socket?.disconnect();
		};
	}, [dispatch, tokens]);

	return <SocketContext.Provider value={{ socket, isConnected }}>{children}</SocketContext.Provider>;
};

export default SocketContext;
