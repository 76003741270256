// Import necessary components and hooks, removing layout-related ones
import { Switch } from "@headlessui/react";
import LoadingModal from "LoadingModal";
import AddRecordingModal from "components/AddRecordingModal";
import DeletRecordModal from "components/DeletRecordModal";
import EditNameModal from "components/EditNameModal";
import RecordingViewerModal from "components/RecordingViewerModal";
import Toast from "components/common/Toast";
import { SOMETHING_WENT_WRONG_MESSAGE } from "constant";
import { useAppDispatch, useAppSelector } from "hooks";
import useUserHook from "hooks/useUserHook";
import { useEffect, useState } from "react";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { Recording, getRecordings, selectRecordings, updateRecording } from "store/reducers/recordingReducer";

interface RecordingsTableProps {
    businessName?: string;
}

const RecordingsTable = ({ businessName = '' }: RecordingsTableProps) => {
    const [showRecording, setShowRecording] = useState(false);
    const [remove, setRemove] = useState(false);
    const [viewRecording, setViewRecording] = useState(false);
    const dispatch = useAppDispatch();
    const recordings = useAppSelector(selectRecordings);
    const [loading, setLoading] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const { getCurrentUser } = useUserHook();

    const [activeRecoring, setActiveRecording] = useState<Recording | null>(null);

    const [isInitialLoading, setIsInitialLoading] = useState(true);

    useEffect(() => {
        const fetchRecordings = async () => {
            try {
                await dispatch(getRecordings());
            } catch (error) {
                Toast.error(SOMETHING_WENT_WRONG_MESSAGE);
            } finally {
                setIsInitialLoading(false);
            }
        };

        fetchRecordings();
    }, [dispatch]);

    const handleToggleSwitch = async (recording: Recording, checked: boolean) => {
        try {
            setLoading(true);
            await dispatch(
                updateRecording({ id: recording.id, status: checked ? "active" : "inactive", name: recording.name }),
            );
            setLoading(false);
            dispatch(getRecordings());
            await getCurrentUser();
        } catch (e) {
            setLoading(false);
            Toast.error(SOMETHING_WENT_WRONG_MESSAGE);
        }
    };

    const handleViewRecording = (recording: Recording) => {
        setActiveRecording(recording);
        setViewRecording(true);
    };

    function closeModal() {
        setShowRecording(false);
    }

    function openModal() {
        setShowRecording(true);
    }

    const handleDeleteClick = (recording: Recording) => {
        setRemove(true);
        setActiveRecording(recording);
    };

    const handleEditClick = (recording: Recording) => {
        console.log("handleclick");
        setOpenEdit(true);
        setActiveRecording(recording);
    };
    // Sort recordings by newest first
    console.log(recordings)
    const sortedRecordings = [...recordings].sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    return (
        <>
            <LoadingModal open={loading || isInitialLoading} />
            {!isInitialLoading && (
                <div className="max-w-6xl w-full">
                    <div className="mb-4 flex justify-between items-center">
                        <p className="text-sm text-gray-600 italic">
                            Need script ideas? How about: "Thanks for calling {businessName}, sorry I missed you! I'm going to send you a text right now, or you can leave a message and I'll get back to you right away!"
                            <br />
                            Need a voice actor? You can generate a recording with AI at <a href="https://elevenlabs.io" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">ElevenLabs</a> and upload it here.
                        </p>
                        <button
                            onClick={openModal}
                            className="group bg-secondary border border-secondary rounded-md py-1.5 px-4 text-white text-base font-bold text-center hover:bg-white hover:text-secondary transition duration-500 flex items-center gap-1"
                        >
                            <span className="text-xl h-[30px]">+</span> Add new voice greeting
                        </button>
                    </div>
                    <div className="bg-white shadow-xl border border-gray-300 rounded-lg recording-table">
                        <div className="overflow-x-auto">
                            <table className="w-full min-w-[942px]">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2 text-left">Name</th>
                                        <th className="px-4 py-2 text-left">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recordings.length === 0 ? (
                                        <tr>
                                            <td colSpan={3} className="text-center py-4">
                                                No recordings found
                                            </td>
                                        </tr>
                                    ) : (
                                        recordings.map((recording, index) => (
                                            <tr key={index}>
                                                <td className="px-4 py-2">
                                                    <div className="flex items-center gap-2">
                                                        <button
                                                            onClick={() => handleViewRecording(recording)}
                                                            className="text-[#005DBA] hover:text-[#023e7a] transition duration-400"
                                                        >
                                                            {recording.name}
                                                        </button>
                                                        {recording.status === "active" && (
                                                            <span
                                                                className="inline-flex px-2 py-0.5 text-xs font-medium bg-green-300 text-green-800 rounded-full"
                                                            >
                                                                active
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="px-4 py-2 flex gap-2">
                                                    <Switch
                                                        checked={recording.status === "active"}
                                                        onChange={(checked) => handleToggleSwitch(recording, checked)}
                                                        className={`${recording.status === "active" ? "bg-blue-500" : "bg-gray-200"}
                                                            relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                                    >
                                                        <span className={`${recording.status === "active" ? "translate-x-5" : "translate-x-0"}
                                                            pointer-events-none h-5 w-5 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                                                        />
                                                    </Switch>
                                                    <button onClick={() => handleEditClick(recording)}>
                                                        <MdEdit className="text-blue-500" />
                                                    </button>
                                                    {recording.status !== "active" && (
                                                        <button onClick={() => handleDeleteClick(recording)}>
                                                            <CiTrash className="text-red-500" />
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
            <AddRecordingModal showRecording={showRecording} closeModal={closeModal} />
            {openEdit && <EditNameModal open={openEdit} onClose={() => setOpenEdit(false)} recording={activeRecoring} />}
            <DeletRecordModal remove={remove} setRemove={setRemove} id={activeRecoring?.id} />
            <RecordingViewerModal
                viewRecording={viewRecording}
                setViewRecording={setViewRecording}
                url={activeRecoring?.voiceMailUrl}
            />
        </>
    );
};

export default RecordingsTable;