import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSocket } from "Providers/SocketProvider";
import Breadcrumb from "components/Breadcrumb";
import Toast from "components/common/Toast";
import AuthPage from "hocs/ProtectedRoute";
import useSubScriptionHook from "hooks/subScriptionHook";
import useAuthHook from "hooks/useAuthHook";
import useUserHook from "hooks/useUserHook";
import Layout from "layout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PaymentStatus, getPaymentPlan } from "utils/getPaymentPlan";
import API from "../../utils/axios";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL as string;

const getDaysUntil = (dateString: string) => {
	const today = new Date();
	const futureDate = new Date(dateString);
	const diffTime = futureDate.getTime() - today.getTime();
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	return diffDays;
};

const PaymentUpdateModal = ({
	isOpen,
	onClose,
	onSuccess
}: {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: (paymentMethod: any) => void;
}) => {
	const [loading, setLoading] = useState(false);
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (!stripe || !elements) {
			Toast.error('Payment processing not initialized');
			return;
		}

		setLoading(true);
		try {
			const cardElement = elements.getElement(CardElement);
			if (!cardElement) {
				Toast.error('Card element not found');
				return;
			}

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
			});

			if (error) {
				Toast.error(error.message || 'Payment method update failed');
				return;
			}

			await API.post('subscriptions/payment-method', {
				paymentMethodId: paymentMethod.id
			});

			Toast.success('Payment method updated successfully');
			onSuccess(paymentMethod);
			onClose();
		} catch (err) {
			Toast.error('Failed to update payment method');
		} finally {
			setLoading(false);
		}
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
			<div className="bg-white p-8 rounded-lg max-w-md w-full">
				<h3 className="text-xl font-medium text-gray-900 mb-6">Update Payment Method</h3>
				<form onSubmit={handleSubmit}>
					<div className="mb-6">
						<label className="block text-sm font-medium text-gray-700 mb-2">
							Card Details
						</label>
						<div className="border rounded-md p-4 bg-white shadow-sm">
							<CardElement
								options={{
									style: {
										base: {
											fontSize: '16px',
											color: '#32325d',
											fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
											fontSmoothing: 'antialiased',
											'::placeholder': {
												color: '#aab7c4'
											}
										},
										invalid: {
											color: '#fa755a',
											iconColor: '#fa755a'
										}
									}
								}}
							/>
						</div>
					</div>
					<div className="space-y-3">
						<div className="flex justify-end gap-3">
							<button
								type="button"
								onClick={onClose}
								className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md border"
							>
								Cancel
							</button>
							<button
								type="submit"
								disabled={loading}
								className="px-4 py-2 bg-[#635bff] text-white rounded-md hover:bg-[#5851ea] disabled:opacity-50"
							>
								{loading ? 'Updating...' : 'Update Card'}
							</button>
						</div>
						<div className="flex items-center justify-center text-sm text-gray-500 gap-2">
							<svg className="h-4 w-4" viewBox="0 0 24 24" fill="none">
								<path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							Secure payment powered by Stripe
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

function Subscription() {
	const [plans, setPlans] = useState([]);
	const { user, logout } = useAuthHook();
	const [clientSecretKey, setClientSecretKey] = useState(null);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const { getCurrentUser } = useUserHook();
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState<any>();
	const { socket } = useSocket();
	const { createSubscription } = useSubScriptionHook();
	const [cancelSubscriptionLoading, setcancelSubscriptionLoading] = useState(false);
	const [couponCode, setCouponCode] = useState("");
	const [applyCouponLoading, setApplyCouponLoading] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState<any>(null);
	const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
	const [showUpdatePayment, setShowUpdatePayment] = useState(false);
	const stripe = useStripe();
	const elements = useElements();

	const plan = getPaymentPlan(user);
	const isActivePlan = plan === PaymentStatus.ACTIVE;
	const isPastDue = plan === PaymentStatus.PAST_DUE;
	const isOnTrial = plan === PaymentStatus.TRIAL;
	const isInComplete = plan === PaymentStatus.INCOMPLETE;

	const dispatch = useDispatch();

	useEffect(() => {
		// Fetch the user data when the component mounts
		getCurrentUser();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	document.title = "Skip the Call | Subscription";

	useEffect(() => {
		if (!socket) {
			return;
		}
		socket?.on("payment-status", async (data: any) => {
			console.log("payment success");
			if (data.status === "successful") {
				await getCurrentUser();
				setLoading(false);
				Toast.success("Plan upgraded");
			}
		});
	}, [socket]);

	useEffect(() => {
		API.get("subscriptions/plans").then((res) => {
			setPlans(res.data);
		});
	}, []);

	const handleNewPlanClick = async (plan: any) => {
		setSelectedPlan(plan);
		setShowConfirmationModal(true);
	};

	const handleChangePlanAfterConfirmation = async () => {
		setLoading(true);
		setShowConfirmationModal(false);
		if (isActivePlan || isPastDue) {
			API.post(`subscriptions/update/`, { customerId: user?.account.stripeUserId, status: plan })
				.then(async (res) => {
					setOpen(true);
					setLoading(false);
					const { clientSecret } = res.data;
					setClientSecretKey(clientSecret);
				})
				.catch((err) => {
					Toast.error(err.data.message.message);
					setOpen(true);
					setLoading(false);
				});
		} else if (isInComplete) {
			await API.patch(`subscriptions/cancel/`);
			const res = await createSubscription({
				priceId: selectedPlan?.stripePriceId.toString() ?? "",
				noTrial: true,
			});

			setClientSecretKey(res.payload.clientSecret);
			console.log({ res });
			setOpen(true);
			setLoading(false);
		} else if (isOnTrial) {
			await API.post(`subscriptions/trialing/`, { priceId: selectedPlan?.stripePriceId.toString() ?? "" });
			setOpen(true);
			setLoading(false);
		}
	};

	const handleCancelSubscription = async () => {
		setcancelSubscriptionLoading(true);
		await API.patch(`subscriptions/cancel/`);
		logout();
		setcancelSubscriptionLoading(false);
	};

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			getCurrentUser();
		}, 3000);
	};

	const handleApplyCoupon = async () => {
		if (!couponCode.trim()) {
			Toast.error("Please enter a coupon code");
			return;
		}

		setApplyCouponLoading(true);
		try {
			const response = await API.post("subscriptions/apply-coupon", { couponCode });
			Toast.success(response.data.message || "Coupon applied successfully");
		} catch (error) {
			const errorMessage = error instanceof Error ? error.message : "Failed to apply coupon";
			Toast.error(errorMessage);
		} finally {
			setApplyCouponLoading(false);
		}
	};

	useEffect(() => {
		const fetchPaymentMethod = async () => {
			setPaymentMethodLoading(true);
			try {
				const response = await API.get('subscriptions/payment-method');
				setPaymentMethod(response.data[0]);
			} finally {
				setPaymentMethodLoading(false);
			}
		};

		if (user?.account?.subscription?.[0]) {
			fetchPaymentMethod();
		}
	}, [user]);

	const handlePaymentSuccess = async () => {
		const response = await API.get('subscriptions/payment-method');
		setPaymentMethod(response.data[0]);
	};

	return (
		<Layout>
			<div className="lg:flex">
				<div className="lg:ml-[75px] lg:pt-0 pt-14 lg:w-[calc(100%-75px)] w-full h-screen">
					<Breadcrumb />

					<div className="max-w-3xl mx-auto w-full py-4 p-4">
						<div className="bg-white rounded-lg shadow p-4">
							{user?.account?.subscription?.[0] ? (
								<div className="grid grid-cols-2 md:grid-cols-3 gap-3 text-sm">
									<div>
										<span className="text-gray-500 block">Plan</span>
										<span className="font-medium">
											{user.account.subscription[0].subscriptionsPlan.type} -
											{user.account.subscription[0].subscriptionsPlan.currency}
											{user.account.subscription[0].subscriptionsPlan.amount}/mo
										</span>
									</div>

									<div>
										<span className="text-gray-500 block">Status</span>
										<span className="font-medium">
											{user.account.subscription[0].status === 'trialing' ? (
												<>Free trial for {getDaysUntil(user.account.subscription[0].endDate)} more days</>
											) : (
												user.account.subscription[0].status
											)}
										</span>
									</div>

									<div>
										<span className="text-gray-500 block">Next Payment</span>
										<span className="font-medium">
											{new Date(user.account.subscription[0].endDate).toLocaleDateString()}
											<span className="text-gray-500 ml-1">
												(in {getDaysUntil(user.account.subscription[0].endDate)} days)
											</span>
										</span>
									</div>

									{user.account.isTrial && (
										<div>
											<span className="text-gray-500 block">Trial Ends</span>
											<span className="font-medium">
												{new Date(user.account.trialEnd).toLocaleDateString()}
											</span>
										</div>
									)}

									<div className="space-y-2">
										<span className="text-gray-500 block font-medium">Payment Method</span>
										{paymentMethodLoading ? (
											<span className="text-gray-400">Loading...</span>
										) : paymentMethod?.card ? (
											<div className="space-y-3">
												<div className="flex items-center gap-2">
													<span className="font-medium">
														•••• {paymentMethod.card.last4}
														({paymentMethod.card.brand.toUpperCase()})
													</span>
													<div className="flex gap-2">
														<button
															onClick={() => setShowUpdatePayment(true)}
															className="text-blue-600 text-xs hover:underline"
														>
															Update
														</button>
														<button
															onClick={async () => {
																if (window.confirm('Are you sure you want to remove this payment method?')) {
																	try {
																		await API.delete(`subscriptions/payment-method/${paymentMethod.id}`);
																		await handlePaymentSuccess();
																		Toast.success('Payment method removed');
																	} catch (err) {
																		Toast.error('Failed to remove payment method');
																	}
																}
															}}
															className="text-red-600 text-xs hover:underline"
														>
															Remove
														</button>
													</div>
												</div>
												<div className="text-sm text-gray-600 space-y-1">
													<div>Expires: {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</div>
													<div>Added: {new Date(paymentMethod.created * 1000).toLocaleDateString()}</div>
												</div>
											</div>
										) : (
											<div>
												<span className="text-gray-400 block mb-2">No payment method found</span>
												<button
													onClick={() => setShowUpdatePayment(true)}
													className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 text-sm"
												>
													Add Payment Method
												</button>
											</div>
										)}
									</div>
								</div>
							) : (
								<div className="text-gray-600 text-sm">
									No active subscription found. Please select a plan to continue.
								</div>
							)}
						</div>
					</div>

					{/* Payment Update Modal */}
					<PaymentUpdateModal
						isOpen={showUpdatePayment}
						onClose={() => setShowUpdatePayment(false)}
						onSuccess={handlePaymentSuccess}
					/>
				</div>
			</div>
		</Layout>
	);
}

export default AuthPage(
	() => (
		<Elements stripe={stripePromise}>
			<Subscription />
		</Elements>
	)
);
