import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import SetViewportHeight from "./utils/SetViewportHeight";
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

posthog.init(
    process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string,
    {
      api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
      person_profiles: 'identified_only',
    }
  );

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<>
    <PostHogProvider client={posthog}>
        <SetViewportHeight />
        <App />
    </PostHogProvider>
</>);

serviceWorkerRegistration.register();
